define("formaviva-web/_pods/components/shipping/by-quantity-price-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EzNbZ6lO",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"class\",\"data-test-by-quantity-price-form\"],[[25,[\"price\"]],\"shipping-price-by-quantity-form\",true]],{\"statements\":[[0,\"  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"shipping-price-by-quantity-form__td value first\"],[9],[0,\"\\n          \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('formaviva-web/_pods/components/shipping/by-quantity-price-form/template.hbs' @ L9:C12) \"],null]],[[\"controlType\",\"property\",\"data-test-shipping-price-per-unit\"],[\"text\",\"pricePerUnit\",true]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"shipping-price-by-quantity-form__td text\"],[9],[0,\"\\n          EUR for 1 unit\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"shipping-price-by-quantity-form__td value with-indent\"],[9],[0,\"\\n          \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('formaviva-web/_pods/components/shipping/by-quantity-price-form/template.hbs' @ L18:C12) \"],null]],[[\"controlType\",\"property\",\"data-test-shipping-per-additional\"],[\"text\",\"pricePerAdditionalUnit\",true]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"shipping-price-by-quantity-form__td text\"],[9],[0,\"\\n          EUR more per additional unit\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shipping/by-quantity-price-form/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/components/release/release-buy-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OBLVv7Fs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"release\",\"merches\",\"firstObject\",\"isPhysical\"]]],null,{\"statements\":[[4,\"unless\",[[25,[\"release\",\"merches\",\"firstObject\",\"isAvailable\"]]],null,{\"statements\":[[0,\"    \"],[4,\"bs-tooltip\",null,[[\"placement\",\"class\"],[\"top\",\"mb-2\"]],{\"statements\":[[0,\"Sold out for now\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"button\"],[11,\"data-test-add-vinyl\",\"\"],[11,\"class\",\"btn btn-primary-3 dont-trigger-shopping-cart-toggle\"],[12,\"disabled\",[29,\"not\",[[25,[\"release\",\"merches\",\"firstObject\",\"isAvailable\"]]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[29,\"fa-icon\",[\"cart-plus\"],null],false],[0,\"\\n    Buy \"],[1,[25,[\"release\",\"merches\",\"firstObject\",\"merchType\",\"itemType\"]],false],[0,\" | \"],[1,[25,[\"release\",\"merches\",\"firstObject\",\"price\"]],false],[0,\"€\\n  \"],[3,\"action\",[[24,0,[]],\"addToCart\",[25,[\"release\",\"merches\",\"firstObject\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"release\",\"allowPurchase\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"data-test-add-release\",\"\"],[11,\"class\",\"btn btn-primary-3 dont-trigger-shopping-cart-toggle\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"addToCart\",[25,[\"release\"]]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[29,\"fa-icon\",[\"cart-plus\"],null],false],[0,\"\\n    Buy \"],[1,[25,[\"release\",\"releaseType\"]],false],[0,\" | \"],[1,[25,[\"release\",\"price\"]],false],[0,\"€\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-buy-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});
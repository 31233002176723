define("formaviva-web/mirage/factories/playlist", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    price: _faker.default.random.number,
    name: _faker.default.commerce.productName,
    slug: _faker.default.lorem.word,
    artistNames: _faker.default.name.firstName,
    displayName: _faker.default.name.firstName,
    profile: (0, _emberCliMirage.association)(),
    privateToken: _faker.default.lorem.word
  });

  _exports.default = _default;
});
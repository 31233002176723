define("formaviva-web/_pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZvumCHyw",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"head-layout\"],false],[0,\"\\n\"],[1,[29,\"notification-container\",null,[[\"position\"],[\"top\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"showNavbar\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"nav-bar\",null,[[\"currentSession\",\"logout\"],[[25,[\"currentSession\"]],[29,\"route-action\",[\"logout\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[23,\"containerClass\"]],[9],[0,\"\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"showFooter\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"footer-area\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[23,\"player/footer-player-area\"],false],[0,\"\\n\\n\"],[1,[23,\"app-wide-modals\"],false],[0,\"\\n\"],[1,[23,\"unsaved-changes-modal\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"overlay_spinner\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"overlay_modal\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});
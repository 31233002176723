define("formaviva-web/_pods/components/shipping/by-weight-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p2hjOWwM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"shipping-by-weight-price__td\"],[9],[0,\"from\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"shipping-by-weight-price__td value\"],[11,\"data-test-shipping-price-min-weight\",\"\"],[9],[0,\"\\n  \"],[1,[25,[\"price\",\"minWeight\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"shipping-by-weight-price__td weight-measure\"],[9],[0,\"kg\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"shipping-by-weight-price__td value\"],[11,\"data-test-shipping-price-cost\",\"\"],[9],[0,\"\\n  \"],[1,[25,[\"price\",\"cost\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"shipping-by-weight-price__td\"],[9],[0,\"EUR\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shipping/by-weight-price/template.hbs"
    }
  });

  _exports.default = _default;
});
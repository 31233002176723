define("formaviva-web/_pods/analytics/sales/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HqATJFdo",
    "block": "{\"symbols\":[\"filter\",\"options\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6 pl-0 pr-0\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"availableFilters\"]]],null,{\"statements\":[[4,\"let\",[[29,\"get\",[[29,\"get\",[[25,[\"availableFilterOptions\"]],[24,1,[]]],null],\"options\"],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"filter-select\",null,[[\"selectedFilterOption\",\"filterOptions\",\"filterType\"],[[29,\"find-by\",[\"field\",[25,[\"filterByProperty\"]],[24,2,[]]],null],[24,2,[]],[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6 pl-0 pr-0\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary pull-right\"],[11,\"type\",\"button\"],[9],[0,\"\\n      Export CSV\\n    \"],[3,\"action\",[[24,0,[]],\"exportToCSV\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row mt-8\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"data-test-purchases\",\"\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n    \"],[1,[29,\"table/base-layout\",null,[[\"dir\",\"class\",\"sort\",\"rowHeight\",\"model\",\"columns\",\"canToggleHidden\",\"defaultSort\",\"sortChange\",\"onRowClick\",\"emptyTableText\"],[[25,[\"dir\"]],\"sales__table\",[29,\"camelize\",[[25,[\"sort\"]]],null],65,[25,[\"model\"]],[25,[\"columns\"]],true,[25,[\"defaultSort\"]],[29,\"action\",[[24,0,[]],\"sortChange\"],null],[29,\"action\",[[24,0,[]],\"onRowClick\"],null],[29,\"if\",[[29,\"eq\",[[25,[\"filterByProperty\"]],\"all\"],null],\"You haven't made any sales\",\"No sales with such filter criteria\"],null]]]],false],[0,\"\\n\\n      \"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"model\"]],\"analytics.sales\",[25,[\"page\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/analytics/sales/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/mirage/factories/profile", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      return "Profile ".concat(i);
    },
    slug: _faker.default.internet.userName,
    imageUrl: "https://loremflickr.com/320/240",
    followedProfilesCount: 0,
    followed: false
  });

  _exports.default = _default;
});
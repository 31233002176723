define("formaviva-web/mirage/factories/user", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    email: _faker.default.internet.email,
    name: _faker.default.name.firstName,
    profile: (0, _emberCliMirage.association)()
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/components/profile/profile-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5L4NiPlE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"profile.index\",[25,[\"profile\",\"slug\"]]],[[\"class\"],[\"fv-box\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fv-box__cover\"],[9],[0,\"\\n    \"],[1,[29,\"fv-profile-image\",null,[[\"image\",\"defaultName\",\"backgroundColor\",\"alt\",\"title\",\"textColor\",\"class\"],[[25,[\"profile\",\"adjustedImageUrl\"]],[25,[\"profile\",\"name\"]],\"#141414\",\"\",\"\",\"white\",\"fv-box__image\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fv-box__info\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fv-box__info-title\"],[9],[0,\"\\n      \"],[1,[25,[\"profile\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[25,[\"hideFollowButton\"]]],null,{\"statements\":[[4,\"unless\",[[25,[\"isCurrentProfile\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"profile/follow-profile-button\",null,[[\"class\",\"profile\",\"data-test-follow-profile-button\"],[\"fv-box-list__action-item\",[25,[\"profile\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/profile/profile-box/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/components/track/track-buy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wJnG5deP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"data-test-buy-track\",\"\"],[11,\"class\",\"btn-action\"],[12,\"onClick\",[29,\"action\",[[24,0,[]],\"addToShoppingCart\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"btn-action__content\"],[9],[0,\"\\n    \"],[1,[29,\"fa-icon\",[\"shopping-cart\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/track/track-buy/template.hbs"
    }
  });

  _exports.default = _default;
});
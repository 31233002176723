define("formaviva-web/mirage/serializers/tag", ["exports", "formaviva-web/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    include: Object.freeze(["name", "slug"])
  });

  _exports.default = _default;
});
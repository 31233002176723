define("formaviva-web/_pods/profile/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["page", "justDonated", {
      filterByType: "filter[type]"
    }],
    filterByType: null,
    preserveScrollPosition: true,
    page: 1,
    justDonated: null,
    showDonateModal: false,
    currentSession: Ember.inject.service(),
    currentProfile: null,
    router: Ember.inject.service(),
    profile: Ember.computed.alias("model.profile"),
    releases: Ember.computed.alias("model.releases"),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.currentSession.profile.then(function (profile) {
        _this.set("currentProfile", profile);
      });
    },
    tabsArray: Ember.computed("currentProfile.id", "currentSession.profile", "profile.{approved,associatedMixesCount,associatedReleasesCount,followedProfilesCount,followersCount,id,isListener,likesCount,playlistsCount,publicPlaylistsCount,slug}", "userAgent.device.isMobile", function () {
      var tabs = [];

      if (this.userAgent.device.isMobile) {
        tabs.push({
          name: "About",
          route: "profile.about",
          model: this.profile.slug,
          currentWhen: "profile.about"
        });
      }

      if (!this.profile.isListener) {
        if (this.profile.associatedReleasesCount > 0) {
          tabs.push({
            name: "Releases <span class=\"records__count\">".concat(this.profile.associatedReleasesCount, "</span>"),
            route: "profile.releases",
            model: this.profile.slug,
            currentWhen: "profile.releases profile.index"
          });
        }

        if (this.profile.associatedMixesCount > 0) {
          tabs.push({
            name: "Mixes <span class=\"records__count\">".concat(this.profile.associatedMixesCount, "</span>"),
            route: "profile.mixes",
            model: this.profile.slug,
            currentWhen: this.profile.associatedReleasesCount ? "profile.mixes" : "profile.mixes profile.index"
          });
        }
      }

      if (this.profile.likesCount > 0 || this.profile.isListener) {
        tabs.push({
          name: this.profile.likesCount > 0 ? "Likes <span class=\"records__count\">".concat(this.profile.likesCount, "</span>") : "Likes",
          route: "profile.likes",
          model: this.profile.slug,
          currentWhen: "profile.likes"
        });
      }

      if (this.profile.followersCount > 0) {
        tabs.push({
          name: this.profile.followersCount > 0 ? "Followers <span class=\"records__count\">".concat(this.profile.followersCount, "</span>") : "Followers",
          route: "profile.followers",
          model: this.profile.slug,
          currentWhen: "profile.followers"
        });
      }

      if (this.profile.followedProfilesCount > 0 || this.profile.isListener) {
        tabs.push({
          name: this.profile.followedProfilesCount > 0 ? "Following <span class=\"records__count\">".concat(this.profile.followedProfilesCount, "</span>") : "Following",
          route: "profile.following",
          model: this.profile.slug,
          currentWhen: "profile.following"
        });
      }

      if (this.profile.playlistsCount > 0 || this.profile.isListener) {
        var isCurrentUser = this.currentProfile && this.currentProfile.id === this.profile.id;
        var playlistCount = isCurrentUser ? this.profile.playlistsCount : this.profile.publicPlaylistsCount;
        tabs.push({
          name: playlistCount > 0 ? "Playlists <span class=\"records__count\">".concat(playlistCount, "</span>") : "Playlists",
          route: "profile.playlists",
          model: this.profile.slug,
          currentWhen: "profile.playlists"
        });
      }

      return tabs;
    })
  });

  _exports.default = _default;
});
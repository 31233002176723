define("formaviva-web/_pods/components/filter-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BS3QSAGm",
    "block": "{\"symbols\":[\"filterOption\"],\"statements\":[[7,\"div\"],[11,\"data-test-filter-select\",\"\"],[11,\"class\",\"filter-select__dropdown\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"filter-select__label\"],[9],[0,\"Show:\"],[10],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchEnabled\",\"placeholder\",\"onchange\"],[[25,[\"filterOptions\"]],[25,[\"selectedFilterOption\"]],false,[29,\"humanize\",[[29,\"concat\",[\"Filter by \",[25,[\"filterType\"]]],null]],null],[29,\"action\",[[24,0,[]],\"setSelectedFilterOption\"],null]]],{\"statements\":[[0,\"    \"],[1,[24,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/filter-select/template.hbs"
    }
  });

  _exports.default = _default;
});
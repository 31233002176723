define("formaviva-web/_pods/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tOxnT4Fb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex-dashboard\"],[9],[0,\"\\n\"],[7,\"h3\"],[9],[0,\"Dashboard\"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"canEdit\"]],[29,\"or\",[[29,\"eq\",[[25,[\"currentRouteName\"]],\"dashboard.stats\"],null],[29,\"eq\",[[25,[\"currentRouteName\"]],\"dashboard.releases\"],null]],null]],null]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[\"release.new\",[25,[\"profile\",\"slug\"]]],[[\"class\",\"data-test-create-release\"],[\"btn btn-primary fv-btn--sm pull-right release-btn\",true]],{\"statements\":[[0,\"Create Release\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[1,[29,\"sub-menu\",null,[[\"class\",\"tabs\"],[\"small-margin\",[29,\"array\",[[29,\"hash\",null,[[\"name\",\"route\"],[\"Stats\",\"dashboard.stats\"]]],[29,\"hash\",null,[[\"name\",\"route\"],[\"Releases\",\"dashboard.releases\"]]],[29,\"hash\",null,[[\"name\",\"route\"],[\"Tracks\",\"dashboard.tracks\"]]],[29,\"hash\",null,[[\"name\",\"route\"],[\"Mixes\",\"dashboard.mixes\"]]]],null]]]],false],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/components/track/track-form-with-upload/component", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === "test";

  var _default = Ember.Component.extend({
    cable: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    uploaderVisible: Ember.computed("track.{processed,private,isProcessing}", function () {
      var track = this.track;
      return !track.isProcessing && (track.private || !track.processed);
    }),
    processingMessages: Object.freeze({
      storage_fetching: "Preparing data",
      encoding: "Converting for playback",
      storage_uploading: "Moving files to a secure cloud storage",
      analyzing: "Analyzing audio",
      waveforming: "Creating waveform"
    }),
    setupConsumer: function setupConsumer() {
      var _this = this;

      if (isTest) {
        return;
      }

      var consumer = this.get("cable").createConsumer(_environment.default.webSocketURL);

      var didReceiveMessage = function didReceiveMessage(data) {
        _this.setProperties(data);

        var trackProcessingStatus = data.track_processing_status;

        if (trackProcessingStatus === "processing_done") {
          _this.set("track.isProcessing", false);

          _this.get("track").reload();
        } else if (trackProcessingStatus === "processing_failed") {
          _this.set("track.isProcessing", false);

          _this.set("uploadFinished", false);

          _this.get("notifications").error("Something went wrong with track playback processing. Try again or try another file format.", {
            autoClear: true,
            clearDuration: 10000
          });
        }
      };

      var subscription = consumer.subscriptions.create({
        channel: "TrackChannel",
        track_id: this.get("track").id
      }, {
        received: didReceiveMessage
      });
      this.set("subscription", subscription);
    },
    didDestroyElement: function didDestroyElement() {
      if (this.get("subscription")) {
        this.get("subscription").unsubscribe();
      }

      this._super.apply(this, arguments);
    },
    actions: {
      setupProgressStreaming: function setupProgressStreaming() {
        this.setupConsumer();
      },
      onUploadFinished: function onUploadFinished() {
        this.set("uploadFinished", true);
      },
      // set track-form-inputs to call resetDisplayName action
      setChildComponent: function setChildComponent(childComponent) {
        this.set("childComponent", childComponent);
      },
      resetDisplayName: function resetDisplayName() {
        if (this.get("childComponent")) {
          this.get("childComponent").send("resetDisplayName");
        }
      }
    }
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3hjt0j5B",
    "block": "{\"symbols\":[\"profile\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row pb-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fv-breadcrumbs\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"labels\"],null,{\"statements\":[[0,\"Labels\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-6\"],[9],[0,\"\\n    \"],[1,[29,\"sortable-select\",null,[[\"class\",\"sortOptions\",\"selectedSortOption\"],[\"sort-labels\",[25,[\"availableSortOptions\"]],[25,[\"selectedSortOption\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"labels\"]],\"labels\",[25,[\"page\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"fv-box-list\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"profile/profile-box\",null,[[\"profile\",\"isCurrentProfile\",\"class\",\"data-test-labels\"],[[24,1,[]],[29,\"eq\",[[24,1,[\"id\"]],[25,[\"currentSession\",\"profile\",\"id\"]]],null],\"fv-box-list__item\",true]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"fv-box-list__placeholder\"],[11,\"data-test-labels\",\"\"],[9],[0,\"There are no labels available right now.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"labels\"]],\"labels\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/labels/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/components/shipping/by-quantity-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iep55kkU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"data-test-price-by-quantity\",\"\"],[11,\"class\",\"shipping-by-quantity-price\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Per unit\"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"data-test-shipping-price-per-unit\",\"\"],[9],[1,[25,[\"price\",\"pricePerUnit\"]],false],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"EUR.\"],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Per additional unit\"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"data-test-shipping-price-per-additional\",\"\"],[9],[1,[25,[\"price\",\"pricePerAdditionalUnit\"]],false],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"EUR.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shipping/by-quantity-price/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/mirage/factories/tag", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: _faker.default.commerce.productName,
    slug: _faker.default.commerce.productId
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/edit-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UC+byOM5",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"sub-menu\",null,[[\"isBreadcrumb\",\"tabs\"],[true,[29,\"array\",[[29,\"hash\",null,[[\"route\",\"model\",\"name\"],[\"profile.index\",[25,[\"profile\",\"slug\"]],[29,\"capitalize\",[[25,[\"profile\",\"name\"]]],null]]]],[29,\"hash\",null,[[\"route\",\"name\"],[\"edit-profile\",\"Edit Profile\"]]]],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row profile-edit-section form-dark\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-8 col-md-offset-2\"],[9],[0,\"\\n    \"],[1,[29,\"profile/profile-form\",null,[[\"profile\",\"goToProfile\"],[[25,[\"profile\"]],[29,\"action\",[[24,0,[]],\"goToProfile\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row with-space-for-player\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-8 col-md-offset-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fv-separator-main\"],[9],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"data-test-profile-save-btn\",\"\"],[11,\"class\",\"btn btn-primary btn-primary-2 mt-12 pull-right\"],[12,\"onclick\",[29,\"route-action\",[\"saveProfile\",[25,[\"profile\"]]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isSaving\"]]],null,{\"statements\":[[0,\"        Saving ...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Save\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/edit-profile/template.hbs"
    }
  });

  _exports.default = _default;
});
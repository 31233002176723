define("formaviva-web/_pods/components/checkout/go-to-payment-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SJ9576+s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"data-test-go-to-payment\",\"\"],[12,\"onclick\",[23,\"onClick\"]],[12,\"disabled\",[23,\"disabled\"]],[12,\"class\",[30,[\"btn btn-primary \",[23,\"class\"]]]],[9],[0,\"\\n  Go to payment \"],[1,[29,\"fa-icon\",[\"angle-right\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/checkout/go-to-payment-button/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/components/country-with-flag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hzjwhzaw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"country-with-flag__flag\"],[9],[0,\"\\n  \"],[1,[29,\"world-flag\",[[25,[\"countryObject\",\"id\"]],16],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"data-test-country-name\",\"\"],[11,\"class\",\"country-with-flag__name\"],[9],[0,\"\\n  \"],[1,[25,[\"countryObject\",\"label\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/country-with-flag/template.hbs"
    }
  });

  _exports.default = _default;
});
define("formaviva-web/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    var user = server.create("user", {
      me: true
    });
    var profile = server.create("profile", {
      user: user
    });
    server.create("shippingOrigin", {
      profile: profile
    });
  }
});
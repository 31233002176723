define("formaviva-web/mirage/factories/shipping-origin", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    address: _faker.default.address.streetAddress,
    city: _faker.default.address.city,
    countryCode: _faker.default.address.countryCode,
    postNumber: "123"
  });

  _exports.default = _default;
});
define("formaviva-web/_pods/components/unsaved-changes-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oLJczvW/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"unsavedChangesModal\",\"unsavedModalShown\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\",\"animatable\",\"clickOutsideToClose\",\"containerClass\"],[true,true,false,\"fv-modal fv-modal--xs\"]],{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"close-X\"],[9],[0,\"X\"],[3,\"action\",[[24,0,[]],\"cancelLeave\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Unsaved Changes\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"You have unsaved changes. Are you sure you want to leave without saving?\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"unsaved-changes-buttons\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-secondary\"],[9],[0,\"Cancel\"],[3,\"action\",[[24,0,[]],\"cancelLeave\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-danger\"],[9],[0,\"Leave\"],[3,\"action\",[[24,0,[]],\"confirmLeave\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/unsaved-changes-modal/template.hbs"
    }
  });

  _exports.default = _default;
});
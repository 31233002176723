define("formaviva-web/mirage/factories/release", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      return "Release ".concat(i);
    },
    releaseDate: new Date(),
    publishedAt: new Date(),
    profile: (0, _emberCliMirage.association)(),
    slug: _faker.default.internet.userName,
    price: _faker.default.commerce.price,
    pricePerTrack: _faker.default.commerce.price,
    withMerches: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(release, server) {
        server.createList('merch', 2, {
          release: release
        });
      }
    })
  });

  _exports.default = _default;
});